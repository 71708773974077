import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export interface RankingResponse {
  items: RankingItems;
}

export interface RankingItems {
  group: Group;
  freeSocial: number;
  freeCommon: number;
  freeSop: number;
  listSocial: Child[];
  listCommon: Child[];
  listSop: Child[];
}

export interface Child {
  firstName: string;
  middleName: string;
  lastName: string;
  points: number;
  wishOrder: number;
}

export interface Group {
  kinderGarden: KinderGarden;
}

export interface KinderGarden {
  name: string;
  id: string;
}

@Injectable()
export class RankingService {

  constructor(private http: HttpClient) { }

  // 145 https://kg.sofia.bg/api/stat-rating/waiting/5770
  // 131 https://kg.sofia.bg/api/stat-rating/waiting/5772
  getRanking(schoolId: string, callback: (response: RankingResponse) => void) {
    this.http.get(`https://kg.sofia.bg/api/stat-rating/waiting/${schoolId}`)
      .subscribe((r: any) => {
        callback(r);
      })
  }
}

const testResponse = {
  "content":null,
  "error":null,
  "itemCount":null,
  "items":{
     "group":{
        "id":5772,
        "kinderGarden":{
           "id":438,
           "communications":null,
           "groups":null,
           "address":null,
           "headOffice":{
              "id":611,
              "communications":null,
              "groups":null,
              "address":null,
              "headOffice":null,
              "detskoZavedenieType":{
                 "id":10,
                 "label":"СУ",
                 "school":true
              },
              "director":null,
              "sofiaRegion":null,
              "sofiaRegionExtra":null,
              "name":"Климент Аркадиевич Тимирязев",
              "external":131,
              "mapUrl":null,
              "eik":"000668708",
              "dateCreated":"2021-03-22T22:00:00.000+0000",
              "dateClosed":null,
              "active":true,
              "infoForParents":null,
              "tmpCrit":null,
              "esriId":598
           },
           "detskoZavedenieType":{
              "id":8,
              "label":"ПГУ",
              "school":false
           },
           "director":null,
           "sofiaRegion":null,
           "sofiaRegionExtra":null,
           "name":"СУ \"Kлимент Арк. Тимирязев\" (полудневна организация)",
           "external":131,
           "mapUrl":"https://www.google.com/maps/d/viewer?authuser=0&hl=bg&mid=zBJvLL_EO4pE.kkhSC76Er9fk&msa=0&ll=42.63409039996877%2C23.380294992969993&z=19",
           "eik":"000668708",
           "dateCreated":"2018-02-26T22:00:00.000+0000",
           "dateClosed":null,
           "active":true,
           "infoForParents":"<p>35 години 131. училище \"Климент А.Тимирязев\" посреща своите ученици.</p>\r\n<p>На 4 март 1985 година е&nbsp; направено официално откриване на 131. ОУ. През април същата година на училището се дава името Климент Аркадиевич Тимирязев.</p>\r\n<p>През 1995 година училището се преобразува в СОУ със заповед на МОН.</p>\r\n<p>От 2016 година вече е средно училище, съгласно новия Закон за предучилищното и училищното образование.</p>\r\n<p>За повече информация посетете нашия сайт.</p>",
           "tmpCrit":null,
           "esriId":438
        },
        "draft":2015,
        "capacity":66,
        "capacitySop":6,
        "capacityHz":0,
        "firstRating":"2021-05-13T21:00:00.000+0000",
        "acceptingFrom":"2021-08-31T21:00:00.000+0000",
        "spotsChangeUncommited":0,
        "active":true
     },
     "freeSocial":20,
     "freeCommon":46,
     "freeSop":6,
     "freeHz":0,
     "freeSpots":null,
     "listSocial":[
        {
           "id":3035700,
           "firstName":"В",
           "middleName":"А",
           "lastName":"А",
           "points":16.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15012150",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>14.0 т. (Общи критерии)"
        },
        {
           "id":3918461,
           "firstName":"Г",
           "middleName":"Г",
           "lastName":"Й",
           "points":16.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000914",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>14.0 т. (Общи критерии)"
        },
        {
           "id":3037136,
           "firstName":"Н",
           "middleName":"А",
           "lastName":"А",
           "points":16.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15012151",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>14.0 т. (Общи критерии)"
        },
        {
           "id":4173671,
           "firstName":"Д",
           "middleName":"С",
           "lastName":"Д",
           "points":15.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15002210",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>13.0 т. (Общи критерии)"
        },
        {
           "id":4173677,
           "firstName":"К",
           "middleName":"С",
           "lastName":"Д",
           "points":15.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15002209",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>13.0 т. (Общи критерии)"
        },
        {
           "id":3617045,
           "firstName":"Б",
           "middleName":"Д",
           "lastName":"Т",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15002090",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>12.0 т. (Общи критерии)"
        },
        {
           "id":4353454,
           "firstName":"В",
           "middleName":"Б",
           "lastName":"Б",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003117",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>12.0 т. (Общи критерии)"
        },
        {
           "id":3100591,
           "firstName":"Г",
           "middleName":"И",
           "lastName":"Г",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15000245",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"4.0 т. (Соц. критерии)</br>10.0 т. (Общи критерии)"
        },
        {
           "id":2962006,
           "firstName":"М",
           "middleName":"И",
           "lastName":"Н",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15008876",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>12.0 т. (Общи критерии)"
        },
        {
           "id":3359659,
           "firstName":"С",
           "middleName":"И",
           "lastName":"Ц",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003317",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 (Соц. критерии)</br>12.0 (Общи критерии)"
        },
        {
           "id":3462894,
           "firstName":"Ф",
           "middleName":"Я",
           "lastName":"М",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15008550",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>11.0 т. (Общи критерии)"
        },
        {
           "id":4292037,
           "firstName":"Е",
           "middleName":"А",
           "lastName":"Х",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15000639",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>10.0 т. (Общи критерии)"
        },
        {
           "id":3873430,
           "firstName":"К",
           "middleName":"И",
           "lastName":"Ц",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15006399",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>9.0 т. (Общи критерии)"
        },
        {
           "id":3874983,
           "firstName":"С",
           "middleName":"П",
           "lastName":"П",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15001218",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>7.0 т. (Общи критерии)"
        }
     ],
     "listCommon":[
        {
           "id":3018780,
           "firstName":"А",
           "middleName":"Л",
           "lastName":"В",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15002093",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3137570,
           "firstName":"Б",
           "middleName":"Л",
           "lastName":"В",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15002092",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":4420327,
           "firstName":"Б",
           "middleName":"Р",
           "lastName":"И",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15002473",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3035700,
           "firstName":"В",
           "middleName":"А",
           "lastName":"А",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15012150",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>14.0 т. (Общи критерии)"
        },
        {
           "id":3311039,
           "firstName":"В",
           "middleName":"Д",
           "lastName":"Д",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000126",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 (Общи критерии)"
        },
        {
           "id":3483532,
           "firstName":"В",
           "middleName":"М",
           "lastName":"П",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003653",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3360527,
           "firstName":"В",
           "middleName":"Я",
           "lastName":"Ш",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003649",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 (Общи критерии)"
        },
        {
           "id":3918461,
           "firstName":"Г",
           "middleName":"Г",
           "lastName":"Й",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000914",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>14.0 т. (Общи критерии)"
        },
        {
           "id":3521803,
           "firstName":"Г",
           "middleName":"Д",
           "lastName":"Д",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15001458",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3570908,
           "firstName":"Д",
           "middleName":"Д",
           "lastName":"М",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15008361",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3266542,
           "firstName":"Д",
           "middleName":"И",
           "lastName":"В",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000190",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3705786,
           "firstName":"И",
           "middleName":"Г",
           "lastName":"Г",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15005675",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3502000,
           "firstName":"К",
           "middleName":"В",
           "lastName":"П",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003718",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3223115,
           "firstName":"К",
           "middleName":"С",
           "lastName":"С",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000083",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":2969389,
           "firstName":"К",
           "middleName":"Х",
           "lastName":"К",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15004831",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3954266,
           "firstName":"Л",
           "middleName":"К",
           "lastName":"Д",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15007234",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3373044,
           "firstName":"М",
           "middleName":"Б",
           "lastName":"Г",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15009792",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":4085030,
           "firstName":"М",
           "middleName":"Д",
           "lastName":"Д",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15006343",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3529827,
           "firstName":"М",
           "middleName":"Л",
           "lastName":"Г",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15008033",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3402992,
           "firstName":"М",
           "middleName":"М",
           "lastName":"И",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003670",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3577538,
           "firstName":"М",
           "middleName":"С",
           "lastName":"В",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003614",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3037136,
           "firstName":"Н",
           "middleName":"А",
           "lastName":"А",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15012151",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>14.0 т. (Общи критерии)"
        },
        {
           "id":3420087,
           "firstName":"Н",
           "middleName":"Е",
           "lastName":"С",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15009098",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3333822,
           "firstName":"Н",
           "middleName":"П",
           "lastName":"П",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15009057",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 (Общи критерии)"
        },
        {
           "id":3777568,
           "firstName":"Н",
           "middleName":"Ц",
           "lastName":"Н",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15002702",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (Общи критерии)"
        },
        {
           "id":3311038,
           "firstName":"Ю",
           "middleName":"Д",
           "lastName":"Д",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000125",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"14.0 (Общи критерии)"
        },
        {
           "id":3289118,
           "firstName":"А",
           "middleName":"М",
           "lastName":"М",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15004121",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3036549,
           "firstName":"А",
           "middleName":"П",
           "lastName":"Н",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000454",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":4422412,
           "firstName":"А",
           "middleName":"П",
           "lastName":"Х",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15007839",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3302636,
           "firstName":"Б",
           "middleName":"А",
           "lastName":"Т",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15001638",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3499017,
           "firstName":"Б",
           "middleName":"Б",
           "lastName":"Т",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15007727",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":4058656,
           "firstName":"В",
           "middleName":"А",
           "lastName":"Я",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15002618",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3914439,
           "firstName":"В",
           "middleName":"В",
           "lastName":"М",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15009968",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3426129,
           "firstName":"В",
           "middleName":"Н",
           "lastName":"Н",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15002821",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3384573,
           "firstName":"В",
           "middleName":"С",
           "lastName":"С",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000091",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 (Общи критерии)"
        },
        {
           "id":3641131,
           "firstName":"Г",
           "middleName":"К",
           "lastName":"П",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15012563",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3163308,
           "firstName":"Г",
           "middleName":"М",
           "lastName":"А",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15001123",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3509045,
           "firstName":"Г",
           "middleName":"П",
           "lastName":"У",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000110",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3083939,
           "firstName":"Д",
           "middleName":"Г",
           "lastName":"Д",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000020",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3024487,
           "firstName":"Д",
           "middleName":"Д",
           "lastName":"Б",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000871",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3274709,
           "firstName":"Д",
           "middleName":"И",
           "lastName":"И",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15008125",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":4377949,
           "firstName":"Д",
           "middleName":"М",
           "lastName":"Д",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003050",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":4173671,
           "firstName":"Д",
           "middleName":"С",
           "lastName":"Д",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15002210",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>13.0 т. (Общи критерии)"
        },
        {
           "id":2962836,
           "firstName":"Д",
           "middleName":"Ц",
           "lastName":"П",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15002832",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 (Общи критерии)"
        },
        {
           "id":3276716,
           "firstName":"Е",
           "middleName":"Д",
           "lastName":"Д",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003057",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":4076236,
           "firstName":"Е",
           "middleName":"Н",
           "lastName":"Т",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15012089",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3560376,
           "firstName":"И",
           "middleName":"В",
           "lastName":"К",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000381",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":4419703,
           "firstName":"И",
           "middleName":"И",
           "lastName":"И",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15012533",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3916964,
           "firstName":"Й",
           "middleName":"П",
           "lastName":"П",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15002390",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":4409369,
           "firstName":"К",
           "middleName":"И",
           "lastName":"Т",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15001695",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":4173677,
           "firstName":"К",
           "middleName":"С",
           "lastName":"Д",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15002209",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>13.0 т. (Общи критерии)"
        },
        {
           "id":4428481,
           "firstName":"М",
           "middleName":"Б",
           "lastName":"К",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15005424",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3701505,
           "firstName":"М",
           "middleName":"Д",
           "lastName":"Т",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15002301",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3461917,
           "firstName":"М",
           "middleName":"С",
           "lastName":"А",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000184",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":4088641,
           "firstName":"Н",
           "middleName":"В",
           "lastName":"З",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15012106",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3483148,
           "firstName":"О",
           "middleName":"О",
           "lastName":"В",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15008136",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3724644,
           "firstName":"П",
           "middleName":"И",
           "lastName":"С",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15001813",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":4278282,
           "firstName":"П",
           "middleName":"М",
           "lastName":"П",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15009282",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3447392,
           "firstName":"Р",
           "middleName":"И",
           "lastName":"С",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15001674",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3822751,
           "firstName":"Р",
           "middleName":"П",
           "lastName":"Р",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003072",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":4329724,
           "firstName":"С",
           "middleName":"А",
           "lastName":"Д",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15009910",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3780702,
           "firstName":"С",
           "middleName":"А",
           "lastName":"Ж",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15003834",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3259710,
           "firstName":"С",
           "middleName":"К",
           "lastName":"Р",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000810",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3689690,
           "firstName":"С",
           "middleName":"Л",
           "lastName":"З",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15001061",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3103956,
           "firstName":"С",
           "middleName":"С",
           "lastName":"П",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15002620",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3801023,
           "firstName":"Ц",
           "middleName":"И",
           "lastName":"К",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15004654",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":4315606,
           "firstName":"Ц",
           "middleName":"Л",
           "lastName":"К",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15001806",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3813629,
           "firstName":"Я",
           "middleName":"Б",
           "lastName":"П",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003587",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3648668,
           "firstName":"Я",
           "middleName":"Н",
           "lastName":"Т",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003238",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"13.0 т. (Общи критерии)"
        },
        {
           "id":3617045,
           "firstName":"Б",
           "middleName":"Д",
           "lastName":"Т",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15002090",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>12.0 т. (Общи критерии)"
        },
        {
           "id":4353454,
           "firstName":"В",
           "middleName":"Б",
           "lastName":"Б",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003117",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>12.0 т. (Общи критерии)"
        },
        {
           "id":3355740,
           "firstName":"В",
           "middleName":"Д",
           "lastName":"А",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15007834",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3010107,
           "firstName":"Г",
           "middleName":"П",
           "lastName":"И",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15004560",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3504508,
           "firstName":"Г",
           "middleName":"С",
           "lastName":"М",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15007634",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3173768,
           "firstName":"Е",
           "middleName":"Б",
           "lastName":"П",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15004863",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3235031,
           "firstName":"Е",
           "middleName":"С",
           "lastName":"С",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15001699",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 (Общи критерии)"
        },
        {
           "id":3668672,
           "firstName":"И",
           "middleName":"В",
           "lastName":"Г",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15005838",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":4403646,
           "firstName":"И",
           "middleName":"В",
           "lastName":"И",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15006110",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3676279,
           "firstName":"И",
           "middleName":"И",
           "lastName":"П",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15001363",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3957499,
           "firstName":"Й",
           "middleName":"Й",
           "lastName":"К",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15009597",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3647798,
           "firstName":"Й",
           "middleName":"Р",
           "lastName":"Е",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15010052",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3696576,
           "firstName":"К",
           "middleName":"Г",
           "lastName":"В",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15010210",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3578563,
           "firstName":"К",
           "middleName":"М",
           "lastName":"М",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003294",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3786737,
           "firstName":"К",
           "middleName":"Т",
           "lastName":"К",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15009120",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3937788,
           "firstName":"Л",
           "middleName":"Д",
           "lastName":"П",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15008670",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":4208097,
           "firstName":"М",
           "middleName":"В",
           "lastName":"Т",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15008722",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3570997,
           "firstName":"М",
           "middleName":"Д",
           "lastName":"М",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15001887",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":4255621,
           "firstName":"М",
           "middleName":"Е",
           "lastName":"Н",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15004734",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3793250,
           "firstName":"М",
           "middleName":"И",
           "lastName":"А",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15007917",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3479345,
           "firstName":"М",
           "middleName":"И",
           "lastName":"Д",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15003284",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":2962006,
           "firstName":"М",
           "middleName":"И",
           "lastName":"Н",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15008876",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>12.0 т. (Общи критерии)"
        },
        {
           "id":3908032,
           "firstName":"М",
           "middleName":"Л",
           "lastName":"И",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15008302",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3858326,
           "firstName":"М",
           "middleName":"М",
           "lastName":"А",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15002375",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3539539,
           "firstName":"М",
           "middleName":"Ц",
           "lastName":"Г",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15000497",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3863536,
           "firstName":"Н",
           "middleName":"А",
           "lastName":"Т",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15010789",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3782545,
           "firstName":"Н",
           "middleName":"Ф",
           "lastName":"А",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15000853",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":2998523,
           "firstName":"П",
           "middleName":"М",
           "lastName":"П",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15001529",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 (Общи критерии)"
        },
        {
           "id":3521722,
           "firstName":"Р",
           "middleName":"В",
           "lastName":"Н",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15004601",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3704405,
           "firstName":"Р",
           "middleName":"Д",
           "lastName":"Ш",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15006637",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3437726,
           "firstName":"С",
           "middleName":"В",
           "lastName":"Й",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15004952",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3359659,
           "firstName":"С",
           "middleName":"И",
           "lastName":"Ц",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15003317",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 (Соц. критерии)</br>12.0 (Общи критерии)"
        },
        {
           "id":3866353,
           "firstName":"С",
           "middleName":"С",
           "lastName":"Б",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15000333",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3291222,
           "firstName":"Т",
           "middleName":"А",
           "lastName":"Т",
           "points":12.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15008356",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"12.0 т. (Общи критерии)"
        },
        {
           "id":3535403,
           "firstName":"Б",
           "middleName":"Д",
           "lastName":"Д",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15010097",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":3615935,
           "firstName":"Б",
           "middleName":"Д",
           "lastName":"Д",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15008253",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":4064865,
           "firstName":"Б",
           "middleName":"И",
           "lastName":"Г",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15000880",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":4396672,
           "firstName":"В",
           "middleName":"А",
           "lastName":"И",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15002740",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":4357186,
           "firstName":"В",
           "middleName":"Д",
           "lastName":"Т",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15006797",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":4022757,
           "firstName":"В",
           "middleName":"Е",
           "lastName":"Д",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15008034",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":4069137,
           "firstName":"В",
           "middleName":"М",
           "lastName":"Г",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15008044",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":3814076,
           "firstName":"Г",
           "middleName":"О",
           "lastName":"А",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15000646",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":4027157,
           "firstName":"Д",
           "middleName":"А",
           "lastName":"М",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15000449",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":3637819,
           "firstName":"Д",
           "middleName":"П",
           "lastName":"Е",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15008565",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":3627393,
           "firstName":"Е",
           "middleName":"П",
           "lastName":"П",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15005685",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":3054714,
           "firstName":"М",
           "middleName":"Г",
           "lastName":"Г",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15001240",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":4357189,
           "firstName":"М",
           "middleName":"Д",
           "lastName":"Т",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15006798",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":3681846,
           "firstName":"М",
           "middleName":"Е",
           "lastName":"Б",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15004281",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":3480814,
           "firstName":"М",
           "middleName":"И",
           "lastName":"П",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15009246",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":4422636,
           "firstName":"М",
           "middleName":"И",
           "lastName":"П",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15002402",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":3674683,
           "firstName":"Н",
           "middleName":"Б",
           "lastName":"Б",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15008507",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":4064868,
           "firstName":"Н",
           "middleName":"И",
           "lastName":"Г",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15000881",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":4010941,
           "firstName":"П",
           "middleName":"Г",
           "lastName":"Б",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15000251",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":3839816,
           "firstName":"С",
           "middleName":"С",
           "lastName":"Й",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15011706",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":3784196,
           "firstName":"Т",
           "middleName":"В",
           "lastName":"В",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15002972",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"11.0 т. (Общи критерии)"
        },
        {
           "id":3462894,
           "firstName":"Ф",
           "middleName":"Я",
           "lastName":"М",
           "points":11.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15008550",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>11.0 т. (Общи критерии)"
        },
        {
           "id":3789171,
           "firstName":"Б",
           "middleName":"Р",
           "lastName":"М",
           "points":10.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15004468",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"10.0 т. (Общи критерии)"
        },
        {
           "id":3100591,
           "firstName":"Г",
           "middleName":"И",
           "lastName":"Г",
           "points":10.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15000245",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"4.0 т. (Соц. критерии)</br>10.0 т. (Общи критерии)"
        },
        {
           "id":4424370,
           "firstName":"Д",
           "middleName":"К",
           "lastName":"Г",
           "points":10.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15002633",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"10.0 т. (Общи критерии)"
        },
        {
           "id":4292037,
           "firstName":"Е",
           "middleName":"А",
           "lastName":"Х",
           "points":10.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15000639",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>10.0 т. (Общи критерии)"
        },
        {
           "id":3014152,
           "firstName":"И",
           "middleName":"И",
           "lastName":"Д",
           "points":10.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15005524",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"10.0 (Общи критерии)"
        },
        {
           "id":3657334,
           "firstName":"Й",
           "middleName":"С",
           "lastName":"С",
           "points":10.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15007444",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"10.0 т. (Общи критерии)"
        },
        {
           "id":3886373,
           "firstName":"М",
           "middleName":"З",
           "lastName":"С",
           "points":10.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15003244",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"10.0 т. (Общи критерии)"
        },
        {
           "id":3158776,
           "firstName":"М",
           "middleName":"К",
           "lastName":"С",
           "points":10.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15003385",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"10.0 т. (Общи критерии)"
        },
        {
           "id":3777250,
           "firstName":"М",
           "middleName":"Л",
           "lastName":"М",
           "points":10.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":4,
           "orderPoints":0,
           "socialPoints":null,
           "childNum":"15003708",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"10.0 т. (Общи критерии)"
        },
        {
           "id":3432036,
           "firstName":"Н",
           "middleName":"П",
           "lastName":"П",
           "points":10.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15000286",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"10.0 т. (Общи критерии)"
        },
        {
           "id":4172412,
           "firstName":"Н",
           "middleName":"П",
           "lastName":"П",
           "points":10.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15007546",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"10.0 т. (Общи критерии)"
        },
        {
           "id":3031996,
           "firstName":"П",
           "middleName":"Б",
           "lastName":"М",
           "points":10.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15001391",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"10.0 (Общи критерии)"
        },
        {
           "id":3818721,
           "firstName":"А",
           "middleName":"Т",
           "lastName":"К",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15006625",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 т. (Общи критерии)"
        },
        {
           "id":3030051,
           "firstName":"В",
           "middleName":"Е",
           "lastName":"Н",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15008495",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 (Общи критерии)"
        },
        {
           "id":3220602,
           "firstName":"В",
           "middleName":"Ж",
           "lastName":"Б",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15005375",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 (Общи критерии)"
        },
        {
           "id":4099285,
           "firstName":"В",
           "middleName":"С",
           "lastName":"К",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15008022",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 т. (Общи критерии)"
        },
        {
           "id":4216759,
           "firstName":"Д",
           "middleName":"И",
           "lastName":"Н",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15008285",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 т. (Общи критерии)"
        },
        {
           "id":3834365,
           "firstName":"Д",
           "middleName":"Й",
           "lastName":"С",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15003688",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 т. (Общи критерии)"
        },
        {
           "id":3860592,
           "firstName":"Е",
           "middleName":"Н",
           "lastName":"Н",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15012104",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 т. (Общи критерии)"
        },
        {
           "id":3974934,
           "firstName":"К",
           "middleName":"А",
           "lastName":"Х",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15009071",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 т. (Общи критерии)"
        },
        {
           "id":3839854,
           "firstName":"К",
           "middleName":"И",
           "lastName":"И",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15003925",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 т. (Общи критерии)"
        },
        {
           "id":3873430,
           "firstName":"К",
           "middleName":"И",
           "lastName":"Ц",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15006399",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>9.0 т. (Общи критерии)"
        },
        {
           "id":3532505,
           "firstName":"М",
           "middleName":"П",
           "lastName":"К",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15002525",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 т. (Общи критерии)"
        },
        {
           "id":4433411,
           "firstName":"Н",
           "middleName":"Д",
           "lastName":"М",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15008468",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 т. (Общи критерии)"
        },
        {
           "id":3769743,
           "firstName":"Н",
           "middleName":"И",
           "lastName":"Г",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15012558",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 т. (Общи критерии)"
        },
        {
           "id":3179690,
           "firstName":"Н",
           "middleName":"Н",
           "lastName":"Н",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15009190",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 (Общи критерии)"
        },
        {
           "id":3579085,
           "firstName":"С",
           "middleName":"В",
           "lastName":"Д",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15008949",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 т. (Общи критерии)"
        },
        {
           "id":4122484,
           "firstName":"Я",
           "middleName":"С",
           "lastName":"Д",
           "points":9.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15004176",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"9.0 т. (Общи критерии)"
        },
        {
           "id":3199206,
           "firstName":"Б",
           "middleName":"П",
           "lastName":"Х",
           "points":8.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15003305",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"8.0 (Общи критерии)"
        },
        {
           "id":4110224,
           "firstName":"М",
           "middleName":"А",
           "lastName":"П",
           "points":8.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":5,
           "orderPoints":0,
           "socialPoints":null,
           "childNum":"15008467",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"8.0 т. (Общи критерии)"
        },
        {
           "id":3023280,
           "firstName":"Н",
           "middleName":"В",
           "lastName":"В",
           "points":8.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":4,
           "orderPoints":0,
           "socialPoints":null,
           "childNum":"15005312",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"8.0 т. (Общи критерии)"
        },
        {
           "id":4053181,
           "firstName":"Н",
           "middleName":"П",
           "lastName":"Н",
           "points":8.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":6,
           "orderPoints":0,
           "socialPoints":null,
           "childNum":"15001648",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"8.0 т. (Общи критерии)"
        },
        {
           "id":3725021,
           "firstName":"С",
           "middleName":"С",
           "lastName":"С",
           "points":8.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15012518",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"8.0 т. (Общи критерии)"
        },
        {
           "id":4334961,
           "firstName":"А",
           "middleName":"П",
           "lastName":"И",
           "points":7.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":11,
           "orderPoints":0,
           "socialPoints":null,
           "childNum":"15009892",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"7.0 т. (Общи критерии)"
        },
        {
           "id":3365311,
           "firstName":"Б",
           "middleName":"Б",
           "lastName":"П",
           "points":7.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"15012039",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"7.0 (Общи критерии)"
        },
        {
           "id":3874983,
           "firstName":"С",
           "middleName":"П",
           "lastName":"П",
           "points":7.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"15001218",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"2.0 т. (Соц. критерии)</br>7.0 т. (Общи критерии)"
        },
        {
           "id":3795151,
           "firstName":"В",
           "middleName":"А",
           "lastName":"А",
           "points":6.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":6,
           "orderPoints":0,
           "socialPoints":null,
           "childNum":"15009514",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"6.0 т. (Общи критерии)"
        },
        {
           "id":3685308,
           "firstName":"Е",
           "middleName":"В",
           "lastName":"М",
           "points":6.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":5,
           "orderPoints":0,
           "socialPoints":null,
           "childNum":"15012480",
           "sopPoints":0.0,
           "hzPoints":0.0,
           "displayText":"6.0 т. (Общи критерии)"
        }
     ],
     "listSop":[
        {
           "id":3564744,
           "firstName":"Р",
           "middleName":"Д",
           "lastName":"П",
           "points":21.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":1,
           "orderPoints":3,
           "socialPoints":null,
           "childNum":"14003655",
           "sopPoints":7.0,
           "hzPoints":0.0,
           "displayText":"21.0 т. (СОП)</br>"
        },
        {
           "id":4030152,
           "firstName":"Н",
           "middleName":"Н",
           "lastName":"Н",
           "points":14.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":3,
           "orderPoints":1,
           "socialPoints":null,
           "childNum":"14011484",
           "sopPoints":7.0,
           "hzPoints":0.0,
           "displayText":"14.0 т. (СОП)</br>"
        }
     ],
     "listHz":[
        {
           "id":3541093,
           "firstName":"П",
           "middleName":"Н",
           "lastName":"К",
           "points":13.0,
           "entryDate":null,
           "entryNum":null,
           "wishOrder":2,
           "orderPoints":2,
           "socialPoints":null,
           "childNum":"15009477",
           "sopPoints":0.0,
           "hzPoints":3.0,
           "displayText":"13.0 т. (ХЗ)</br>"
        }
     ],
     "listWaiting":null,
     "listWaitingHzWithChangedDraft":null,
     "listWaitingWithChangedDraft":null,
     "listWaitingSopWithChangedDraft":null
  },
  "locked":false,
  "createdAt":"2021-04-28T06:48:26.323+0000",
  "success":null,
  "token":null
};
