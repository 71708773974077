import { Component, OnInit } from '@angular/core';
import { RankingResponse, RankingService } from 'src/services/ranking.service';

interface ResultInfo {
  percentage: number;
  kinderGarden: string;
  kinderGardenId: string;
  places: number;
  candidates: number;
  wish: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private childPoints = 14;
  percentage = 0;
  kinderGarden = '';
  results: ResultInfo[] = [];

  constructor(private service: RankingService) { }


  ngOnInit(): void {
    this.service.getRanking('5770', (response) => {
      this.processResponse(response, 1);
    });

    this.service.getRanking('5772', (response) => {
      this.processResponse(response, 1);
    });

    this.service.getRanking('5771', (response) => {
      this.processResponse(response, 2);
    });
  }

  private processResponse(response: RankingResponse, wish: number) {
    const items = response.items;
    const listCommon = items.listCommon.filter(c => c.wishOrder <= wish);

    const freeCommon = items.freeCommon;
    const sopLeftOver = Math.max(0, items.freeSop - items.listSop.filter(c => c.wishOrder === 1).length);
    const socialLeftOver = Math.max(0, items.freeSocial - items.listSocial.filter(c => c.wishOrder === 1).length);
    const freeTotal = freeCommon + sopLeftOver + socialLeftOver;

    const filteredChildren = listCommon.filter(c => c.points >= this.childPoints - wish).length;
    const childrenWithSameScore = listCommon.filter(c => c.points === this.childPoints - wish).length;

    const notRanked = filteredChildren - freeTotal;
    const ranked = childrenWithSameScore - notRanked;

    const result: ResultInfo = {
      percentage: ranked / childrenWithSameScore,
      kinderGarden: items.group.kinderGarden.name,
      kinderGardenId: items.group.kinderGarden.id,
      places: ranked,
      candidates: childrenWithSameScore,
      wish: wish
    };
    this.results.push(result);
  }
}
