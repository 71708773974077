<ng-container *ngFor="let result of results">
  <h3><a href="https://kg.sofia.bg/#/kindergarten/{{result.kinderGardenId}}" target="_blank"> {{result.kinderGarden}}</a></h3>
  Шанс:
  <span [ngClass]="{'percent-text': true,
                    'percent-text--good': result.percentage >= 1,
                    'percent-text--medium': result.percentage < 1 && result.percentage >= 0.5,
                    'percent-text--bad': result.percentage < 0.5}"
  >
    {{result.percentage | percent}} ({{result.places}}/{{result.candidates}}) ({{result.wish}} желание)
  </span>
</ng-container>
